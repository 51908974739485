export function selectbox() {
    $(document).ready(function () {

        let financing = $('.financingType-select');
        let comparison = $('.comparisonOffer-select');

        financing.materialSelect();
        comparison.materialSelect();
        $('.logo-select').materialSelect();
        $('.template-select').materialSelect();

        financing.on("change", function() {
            comparison.materialSelect('destroy');
            comparison.empty();

            $('.financingType-select > option').each(function() {

                if(financing.val()!=$(this).val())
                    comparison.append('<option value="'+$(this).val()+'">'+$(this).text()+'</option>');
            });

            comparison.materialSelect();
        });

    });
}
