// mdbootstrap
//import "@macrocom/mdbootstrap-pro/dist/js/bootstrap";
import "@macrocom/mdbootstrap-pro/dist/js/mdb";

import "@macrocom/mdbootstrap-pro/js/dist/buttons";
import "@macrocom/mdbootstrap-pro/js/dist/cards";
import "@macrocom/mdbootstrap-pro/js/dist/character-counter";
import "@macrocom/mdbootstrap-pro/js/dist/chips";
import "@macrocom/mdbootstrap-pro/js/dist/collapsible";
import "@macrocom/mdbootstrap-pro/js/dist/dropdown";
import "@macrocom/mdbootstrap-pro/js/dist/file-input";
import "@macrocom/mdbootstrap-pro/js/dist/forms-free";
import "@macrocom/mdbootstrap-pro/js/dist/material-select";
import "@macrocom/mdbootstrap-pro/js/dist/sticky";

import 'typeface-roboto'
import "popper.js"
import "hammerjs";

import {selectbox} from './components/Form/selectbox';
import {datepicker} from './components/Form/datepicker';

selectbox();
datepicker();

require('../css/app.scss');
(function () {
    'use strict';
    window.addEventListener('load', function () {
// Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
// Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

